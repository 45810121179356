import { postExperimentSession } from "../../api-sdk/v3/routes";
import { BEAM_CART_COOKIE_NAME } from "../../shared/cart-contents";
import { BeamCartCreatedEvent, BeamNonprofitSelectEvent, BeamOrderCreatedEvent } from "../../utils/events";
import { BeamPlugin, BeamConfigOptions } from "../beam";
import { BeamError } from "../../utils/beam-errors";
import { getCookieValue, setCookieValue } from "../../utils/cookies";
import { logger } from "../../utils/logger";
import { saveRemoteSession } from "../../utils/remote-session";

/**
 * StatSig Docs:  https://docs.statsig.com/client/jsClientSDK
 */
type StatsigPluginOptions = {
  statsigApiKey: string;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statsig?: any; // attached by Statsig CDN script
  }
}

const STATSIG_VERSION = 4;

const STATSIG_SCRIPT_URL = `https://cdn.jsdelivr.net/npm/statsig-js@${STATSIG_VERSION}/build/statsig-prod-web-sdk.min.js`;

const STATSIG_STABLE_ID_COOKIE = "beam_statsig_session_id";

const BEAM_VISIBILITY_DATA_ATTRIBUTE = "data-beam-visibility";

// Add class to elements that should only show when Beam shows
const BEAM_VISIBILITY_SYNCED_CLASS = "beam-sync-visibility";

const BEAM_AB_TEST_EVENTS = {
  STATSIG_INIT: "statsig_init",
  CART_CREATED: "cart_created",
  ORDER_CREATED: "order_created",
  BEAM_SELECTION: "beam_selection",
};

type Experiments = {
  /** Whether Beam widgets should be shown to users */
  shouldShowBeam: boolean;
  /** Whether events should be logged to Statsig */
  isLoggingEnabled: boolean;
};

/**
 * Sets up Statsig for A/B testing and shows/hides Beam based on default experiment rules.
 * @example
 * import { StatsigPlugin } from '@beamimpact/web-sdk/dist/integrations/statsig'
 * import { init } from '@beamimpact/web-sdk/dist/integrations/beam'
 *
 * const beam = await init({
 *   apiKey: '',
 *   chainId: 1,
 *   storeId: 1,
 *   domain: 'my-store.com' // in case site uses subdomains for different pages
 *   plugins: [
 *     new StatsigPlugin({ statsigApiKey: '' })
 *   ]
 * })
 *
 * // Once initialized, all Beam widgets have display: none unless user is assigned to A/B test group that shows Beam
 * // To hide additional elements, add the CSS className "beam-sync-visibility" to them
 * // To programmatically access the experiment state: getConfig().plugins.statsig.experiments.shouldShowBeam
 */
export class StatsigPlugin implements BeamPlugin {
  name = "statsig";

  statsig: any; // Statsig SDK instance

  statsigApiKey: string;

  experiments: Experiments = {
    shouldShowBeam: false,
    isLoggingEnabled: true,
  };

  stableId?: string;

  #beamConfig?: BeamConfigOptions;

  // Checked at initialization so that we can tell if post-purchase events came from a session with a cart or not
  #beamCartCookie?: string;

  constructor(options: StatsigPluginOptions) {
    this.statsigApiKey = options.statsigApiKey;
  }

  async init(config: BeamConfigOptions) {
    this.#beamConfig = config;

    // Add event listeners for cart/order lifecycle events
    // This should happen as soon as possible to avoid missing events
    this.#attachEventListeners();

    // Attach beam-visibility data attribute to body
    this.#setBeamVisibilityValue("hide");

    // Create beam a/b test stylesheet
    const beamVisibilityStylesheet = document.createElement("style");
    beamVisibilityStylesheet.innerHTML = `
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-select-nonprofit,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-post-purchase,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-redeem-transaction,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-impact-overview,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-community-impact,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-cumulative-impact,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-product-details-page,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-subscription-management,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-subscription-impact,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-select-subscription-nonprofit,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] beam-social-share,
      [${BEAM_VISIBILITY_DATA_ATTRIBUTE}="hide"] .${BEAM_VISIBILITY_SYNCED_CLASS} {
        display: none;
      }
    `;
    document.head.append(beamVisibilityStylesheet);

    // Create statsig script
    await this.#initializeStatsig();
    this.stableId = this.statsig.getStableID();
    if (!this.stableId) {
      throw new BeamError("Statsig failed to assign stableId", { name: "StatsigInitError" });
    }

    // Send events that happened before Statsig was ready
    this.#flushEventQueue();

    // Save Statsig ID in localStorage for linking to carts later
    saveRemoteSession({ remoteSessionId: this.stableId, apiKey: config.apiKey });

    // Save initial value of cart cookie so that post-purchase events can be filtered by if they had a cart or not
    this.#beamCartCookie = getCookieValue(BEAM_CART_COOKIE_NAME);

    // Cache ID in cookie to preserve ID across subdomains
    setCookieValue({
      name: STATSIG_STABLE_ID_COOKIE,
      value: this.stableId,
      path: "/",
      domain: config.domain,
    });

    // Evaluate Statsig experiment assignments
    this.experiments = {
      // Whether Beam widgets should be shown to users
      shouldShowBeam: this.statsig
        .getLayer("beam_trial_layer")
        .get("show_beam", false /* default to hide */) as boolean,
      // Whether events should be logged to Statsig
      isLoggingEnabled: this.statsig.getLayer("beam_trial_layer").get("enable_logging", true) as boolean,
    };

    // In parallel:
    // A) Make test call to Statsig
    // B) Save stable ID in Beam for attribution to carts/transactions
    await Promise.all([this.#testStatsigConnection(), this.#registerExperimentWithBeam()]);

    // Update beam visibility data attribute, which shows Beam to users in the experiment
    this.#setBeamVisibilityValue(this.experiments.shouldShowBeam ? "show" : "hide");

    // Log result
    logger.debug("Statsig Experiment Assignments", this.experiments);
  }

  #attachEventListeners() {
    // Cart Created
    window.addEventListener(BeamCartCreatedEvent.eventName, (_event: Event) => {
      const event = _event as BeamCartCreatedEvent;
      const { itemCount, cartId, subtotal, currencyCode } = event.detail;
      this.logEvent(BEAM_AB_TEST_EVENTS.CART_CREATED, true, { itemCount, cartId, subtotal, currencyCode });
    });

    // Order Created
    window.addEventListener(BeamOrderCreatedEvent.eventName, (_event: Event) => {
      const event = _event as BeamOrderCreatedEvent;
      const { orderId, cartTotal, currencyCode } = event.detail;
      this.logEvent(BEAM_AB_TEST_EVENTS.ORDER_CREATED, cartTotal, {
        orderId,
        cartTotal,
        currencyCode,
        hasCart: !!this.#beamCartCookie,
      });
    });

    // Beam Selection
    // Store previous value in case widget is recreated and emits event again:
    let lastSelectedNonprofitId: number | null | undefined;
    window.addEventListener(BeamNonprofitSelectEvent.eventName, (_event: Event) => {
      const event = _event as BeamNonprofitSelectEvent;
      const { selectedNonprofitId, selectionId } = event.detail;
      if (selectedNonprofitId !== lastSelectedNonprofitId) {
        this.logEvent(BEAM_AB_TEST_EVENTS.BEAM_SELECTION, true, {
          nonprofitId: selectedNonprofitId,
          widget: "select-nonprofit",
          selectionId,
        });
        lastSelectedNonprofitId = selectedNonprofitId;
      }
    });
  }

  // https://docs.statsig.com/client/jsClientSDK#statsig-options
  async #initializeStatsig() {
    const statsigScript = document.createElement("script");
    statsigScript.src = STATSIG_SCRIPT_URL;
    statsigScript.async = false;
    const statsigReadyPromise = new Promise((res, rej) => {
      statsigScript.addEventListener("load", res);
      statsigScript.addEventListener("error", rej);
    });
    document.head.append(statsigScript);
    try {
      await statsigReadyPromise;
    } catch (err) {
      throw new BeamError("Failed to load Statsig script", { name: "StatsigInitError" });
    }
    this.statsig = window.statsig;
    if (!this.statsig) {
      throw new BeamError("Failed to detect Statsig", { name: "StatsigInitError" });
    }
    // https://docs.statsig.com/client/jsClientSDK#statsig-options
    const statsigOptions = {
      disableErrorLogging: true,
      disableAutoMetricsLogging: true,
      disableCurrentPageLogging: true,
      loggingIntervalMillis: 1000,
      loggingBufferMaxSize: 2,
      overrideStableID: getCookieValue(STATSIG_STABLE_ID_COOKIE) ?? null,
    };
    await this.statsig.initialize(this.statsigApiKey, {}, statsigOptions);
  }

  #eventQueue: Parameters<typeof StatsigPlugin.prototype.logEvent>[] = [];

  #flushEventQueue() {
    while (this.#eventQueue.length > 0) {
      const eventParams = this.#eventQueue.shift();
      if (eventParams) {
        this.logEvent(...eventParams);
      }
    }
  }

  logEvent(name: string, value: string | number | boolean, metadata: Record<string, any> = {}) {
    if (!this.statsig) {
      this.#eventQueue.push([name, value, metadata]);
      return;
    }
    const withBeam = this.experiments.shouldShowBeam;
    if (this.experiments.isLoggingEnabled) {
      this.statsig.logEvent(name, value, { ...metadata, withBeam });
    }
    logger.debug(name, value, { ...metadata, withBeam });
  }

  #setBeamVisibilityValue(value: "show" | "hide") {
    document.body.setAttribute(BEAM_VISIBILITY_DATA_ATTRIBUTE, value);
  }

  async #testStatsigConnection() {
    // Use the HTTP API so we can synchronously detect if Statsig is reachable,
    // rather than pushing an event to the SDK's retryable queue.
    // https://docs.statsig.com/http-api
    const url = "https://events.statsigapi.net/v1/log_event";
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "statsig-api-key": this.statsigApiKey,
        },
        body: JSON.stringify({
          eventName: BEAM_AB_TEST_EVENTS.STATSIG_INIT,
          value: "true",
          time: new Date().getTime() / 1000, // unix timestamp (seconds not milliseconds)
          metadata: {
            show_beam: this.experiments.shouldShowBeam.toString(),
            page: window.location.pathname,
          },
          user: {
            userID: this.stableId,
          },
        }),
      });
    } catch (err) {
      throw new BeamError("Could not call Statsig API", { name: "StatsigInitError", cause: err });
      // TODO: this will log failures to Beam's error tracking API in the future
    }
  }

  async #registerExperimentWithBeam() {
    return postExperimentSession({
      baseUrl: this.#beamConfig?.baseUrl,
      headers: {
        authorization: `Api-Key ${this.#beamConfig?.apiKey}`,
      },
      requestBody: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        remoteSessionId: this.stableId!,
        experiments: {
          showBeam: this.experiments.shouldShowBeam,
        },
      },
    });
  }
}
